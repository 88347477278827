import React, { useState } from "react";

import CloseSVG from "../../../../images/icons/svg/close.svg";
import ShareLinkWhiteSVG from "../../../../images/icons/svg/share-link-icon-white.svg";
import httpClient from "../../../../lib/HttpClient";
import security from "../../../../services/Security";
import Button from "../../../Common/Buttons/ButtonPrimary";
import AdminShareLinkContainer from "./AdminShareLinkContainer";
import CreateModal from "./CreateModal";
import Translation from "./Translation";

import styles from "./Translations.module.scss";

const Translations = (props) => {
  const {
    translations,
    numTranslations,
    docId,
    retrieveTranslations,
    closePanel,
    setShowActivityModal,
    setSelectedShareLink,
  } = props;

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [userRole] = useState(security.getUserRole());

  const createTranslation = async (label, language) => {
    const response = await httpClient.post(`/translations.json`, {
      source_document_id: docId,
      label,
      language
    });

    retrieveTranslations();
    setShowCreateModal(false);
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Translations</div>
          <div className={styles.closeButton} onClick={closePanel}>
            <img src={CloseSVG} />
          </div>
        </div>
        <div className={styles.body}>
          {numTranslations === 0 ? (
            <div className={styles.emptyStateContainer}>
              <div className={styles.emptyState}>
                <div className={styles.shareLinkIcon}>
                  <img src={ShareLinkWhiteSVG} />
                </div>
                <div className={styles.text}>
                  <div className={styles.textOne}>
                    There are no translations
                  </div>
                  <div className={styles.textTwo}>
                    To translate this document with Paige Intelligence, simply
                    click the create button below and select the desired
                    language.
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <React.Fragment>
                {translations &&
                  translations.map((translation) => (
                    <Translation key={translation.id} translation={translation} />
                  ))}
              </React.Fragment>
            </React.Fragment>
          )}
        </div>
        <div className={styles.createNewShareLinkContainer}>
          <Button
            original
            text="Create Translation"
            onClick={() => setShowCreateModal(true)}
          />
        </div>
      </div>
      {showCreateModal && (
        <CreateModal
          closeModal={() => setShowCreateModal(false)}
          createTranslation={createTranslation}
        />
      )}
    </React.Fragment>
  );
};

export default Translations;
