import React, { useState } from "react";
import { UikDropdown, UikDropdownItem, UikDivider, UikButton } from "@uik";
import moment from "moment";

import DownloadSVG from "../../../../images/icons/svg/download-icon.svg";
import ViewsSVG from "../../../../images/icons/svg/views-icon.svg";
import CopySVG from "../../../../images/icons/svg/copy-icon.svg";

import styles from "./Translation.module.scss";
import notificationService from "../../../../services/Notifications";
import httpClient from "../../../../lib/HttpClient";
import Modal from "../../../Common/Modal";

const Translation = (props) => {
  const {
    translation,
    retrieveTranslations,
  } = props;

  const actionsDropDown = ({ onClick }) => {
    return (
      <div className={styles.threeDotMenu} onClick={onClick}>
        &#9679;&#9679;&#9679;
      </div>
    );
  };

  const viewTranslationDocument = (translated_document_id) => {
    if (translated_document_id > 0) {
      window.open(`/documents/${translated_document_id}`);
    }
  };

  return (
    <div className={styles.container} onClick={() => viewTranslationDocument(translation.translated_document_id)}>
      <div className={styles.rowOne}>
        <div className={styles.leftContainer}>
          <div className={styles.downloadsPill}>
            <div className={styles.counter}>
              {translation.status}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.label}>{translation.label}</div>
      <div className={styles.rowThree}>
        <div className={styles.expirationDate}>
          {moment(translation.created_at).format("MMM D, YYYY")}
        </div>
      </div>
    </div>
  );
};

export default Translation;
